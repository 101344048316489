/* Customize the scrollbar track */
::-webkit-scrollbar {
	width: 10px; /* Set the width of the scrollbar */
}

@media (max-width: 768px) {
	::-webkit-scrollbar {
		width: 0px; /* Set the width of the scrollbar */
	}
}
/* Customize the scrollbar thumb (the draggable part) */
::-webkit-scrollbar-thumb {
	background-color: #7ce27e; /* Set the color of the thumb */
	border-radius: 2px; /* Round the corners of the thumb */
}

/* Customize the scrollbar track on hover */
::-webkit-scrollbar:hover {
	width: 16px; /* Increase the width of the scrollbar on hover */
}

/* Customize the scrollbar thumb on hover */
::-webkit-scrollbar-thumb:hover {
	background-color: #555; /* Change the thumb color on hover */
}

/* Customize the scrollbar track when it's in the "active" state (clicked) */
::-webkit-scrollbar-thumb:active {
	background-color: #333; /* Change the thumb color when clicked */
}

/* Customize the scrollbar corner (the intersection of horizontal and vertical scrollbars) */
::-webkit-scrollbar-corner {
	background-color: #ddd; /* Set the color of the scrollbar corner */
}
